const loginComp = () => import("./../../components/loginComp/index.vue");

export default {
    name: "login",
    components: {
        loginComp,
    },
    props: [],
    data() {
        return {};
    },
    computed: {},
    mounted() {
   
    },
    methods: {},
};
